/**
 * 浏览器端请求
 */
import httputil from '../api/http.js'

//获取微信配置文件
export const getWxConfig = (params) => {
    let url ="/weiapi/weixin/getJsSdkSign";
    return httputil.get(url,params);
};

