const wechat = require("../../api/wechat")
import wx from "weixin-js-sdk"

const handleParam = function () {
	// 一些业务需要将url参数进行处理
	let link = addURLpar2(delURLPar2("flag"), "flag=1");
	link = urlDelParam(link, "code");
	link = urlDelParam(link, "state");
	return link;
}
const addURLpar2 = function (destiny, par) {
	if (destiny.indexOf('?') >= 0) {
		return destiny + '&' + par;
	} else {
		return destiny + '?' + par;
	}
}
const delURLPar2 = function (name) {//删除地址栏的参数
	let loca = window.location;
	let baseUrl = loca.origin + loca.pathname + "?";
	let query = loca.search.substr(1);
	if (query.indexOf(name) > -1) {
		let obj = {}
		let arr = query.split("&");
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].split("=");
			obj[arr[i][0]] = arr[i][1];
		};
		delete obj[name];
		let url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
		return url
	} else {
		return loca.href;
	}
}
const urlDelParam = function (url, name) {
	let urlArr = url.split('?');
	if (urlArr.length > 1 && urlArr[1].indexOf(name) > -1) {
		let query = urlArr[1];
		let obj = {}
		let arr = query.split("&");
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].split("=");
			obj[arr[i][0]] = arr[i][1];
		}
		;
		delete obj[name];
		let urlte = urlArr[0] + '?' + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
		return urlte;
	} else {
		return url;
	}
}

/**
 * 微信js-sdk start 用于通用以及其他模块功能需要设置微信分享信息时用
 * 依赖：http://res.wx.qq.com/open/js/jweixin-1.4.0.js，jquery
 * 页面加载完毕后调用wxSdkUtil.setShareInfo(imgUrl, title, desc, callBack);即可
 * yugi : 2019年6月17日
 */
const wxSdkUtil = {
	getWxconfig: function () {// 设置签名
		return new Promise((resolve, reject) => {
			const params = {};
			params.url = window.location.href;
			wechat.getWxConfig(params).then(res => {
				if(res.code==200) {
					const data = res.data || res.msg;
					if(typeof(data)=="object"){
						resolve(data)
					}
					else {
						resolve(JSON.parse(data))
					}
				}
			}).catch(err => {
				reject()
			})
		});
	},
	setShareInfo: function (imgUrl, title, desc, callBack) {
		if (!title) {
			// 标题为空的时候不处理
			return;
		}
		try {
      document.title = title;
      document.getElementsByName('description')[0].content = desc;
    }
		catch (e) {
      console.log(e)
    }
		// 设置分享信息(标题图，标题，描述 ，回调函数)
		this.getWxconfig().then(wxConfig=> {
			// console.log(wxConfig)
			if (!imgUrl) {
				imgUrl = 'http://www.52hrttpic.com/APP/images/logo320.jpg';// 无图时的默认图
			}
			if (!desc) {
				desc = title;
			}
			const link = handleParam();
			const options = {// 分享的默认信息
				title: title, // 分享标题
				desc: desc, // 分享描述
				link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: imgUrl, // 分享图标
				success: function () {
					typeof callBack == "function" && callBack();
				}
			}

			wx.config(wxConfig);
			// 设置微信js-sdk
			wx.ready(function () {
				// 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
				wx.updateAppMessageShareData(options)
				// 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
				wx.updateTimelineShareData(options)
				// 获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口
				wx.onMenuShareWeibo(options)
			});
			// 失败信息打印
			wx.error(function (res) {
				// alert(JSON.stringify(res));
				console.log("js-sdk初始化失败");
				console.log(JSON.stringify(res));
				// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
			});
		})
	}
}

export {wxSdkUtil}
